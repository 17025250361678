import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Carousel from "../components/carousel"
import ImageRow from "../components/image-row"
import Break from "../components/break"
import Titles from "../components/titles"
import SEO from "../components/seo"
import ContactForm from "../components/contact-form"

const images = [{url:"/angela-goh-1.jpg", details:<div><div className="title">Angeline Goh</div><div className="materials">Being Different</div><div className="dimensions"><span>Wood, epoxy, clay figurines</span><span>125 x 31 x 31 cm</span>
<span>Edition of 10</span></div></div>},{url:"/angela-goh-3.jpg", details:<div><div className="title">Angeline Goh</div><div className="materials">Play Ball </div><div className="dimensions"><span>Clay</span><span>30 x 22 x 13 cm</span>
</div></div>}]
const images_highlight = [
  {url:"/isabel-4.jpg", details:<div><div className="title">Isabelle Devos</div><div className="materials">Flight II</div><div className="dimensions"><span>Bronze</span><span>125cm x 55m x 55cm</span>
</div></div>
},{url:"/isabel-5.jpg", details:<div><div className="title">Isabelle Devos</div><div className="materials">Creation of Man</div><div className="dimensions"><span>Bronze</span><span>125cm x 55m x 55cm</span>
</div></div>
},{url:"/isabel-6.jpg", details:<div><div className="title">Isabelle Devos</div><div className="materials">Metamorphosis</div><div className="dimensions"><span>Bronze</span><span>125cm x 55m</span>
</div></div>
}]
const images_highlight2 = [
  {url:"/isabel-7.jpg", details:<div><div className="title">Isabelle Devos</div><div className="materials">Flight II</div><div className="dimensions"><span>Bronze</span><span>125cm x 55m x 55cm</span>
</div></div>
},{url:"/isabel-1.jpg", details:<div><div className="title">Isabelle Devos</div><div className="materials">Creation of Man</div><div className="dimensions"><span>Bronze</span><span>125cm x 55m x 55cm</span>
</div></div>
},{url:"/isabel-2.jpg", details:<div><div className="title">Isabelle Devos</div><div className="materials">Metamorphosis</div><div className="dimensions"><span>Bronze</span><span>125cm x 55m</span>
</div></div>
}]

const IndexPage = () => (<div>
  <Layout>
    <SEO title="Services" />
    <div style={{ }}>
      
      
      

      
      
      <h1 style={{marginBottom:"60px", fontSize:"36px",marginTop:"80px", textAlign:"center"}}>Returns Policy</h1>
      <ImageRow rowType={"largeRow"} images={[{type:"text", text: <div >
    <p style={{padding:"40px"}}>
      Online sales have a 4 day period for the purchaser to decide if the artwork meets their expectations.<br /><br />

      A full refund will be given once the artwork is returned at the purchaser’s expense and after the gallery is satisfied the work is in the same condition that it was originally dispatched. If the returned artwork is damaged or broken, restoration is at the expense of the client and will be deducted from the refund.
<br /><br />
      If you receive a damaged artwork or one that does not match the original order, Frances Keevil must be notified immediately. The incorrect or damaged artwork must be returned within 10 days from the receipt of goods for consideration of a refund.
<br /><br />
      Please contact frances@franceskeevil.com.au for any issues regarding your order and artwork.
        
    </p>  </div>, span:"12"}] } />
      </div>


  </Layout>

</div>
)

export default IndexPage
